// ** Reducers Imports
import navbar from "../navbar";
import layout from "../layout";
import selleraccountReducer from "./selleraccountReducer";
const rootReducer = {
  // auth,
  navbar,
  layout,
  selleraccountReducer,
};

export default rootReducer;