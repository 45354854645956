import { StoreAction } from "../../@core/constants";

const initialState = {
  seller_account_data: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_SELLER_ACCOUNT_DATA:
      return {
        ...state,
        seller_account_data: action.payload,
      };
    default:
      return state;
  }
}
