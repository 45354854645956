export const ApiRoutes = {
  API_HOSTNAME: process.env.REACT_APP_API_HOSTNAME,
  GETNOTES: "getnotes",

  USERLOGIN: "userlogin",

  // PRODUCT'S URL
  INSERTPRODUCT: "insertproducts",
  GETPRODUCT: "getproducts",
  PRODUCTSDELETE: "deleteproducts",
  IMPORTPRODUCTS: "importproducts",
  UPDATEPRODUCTS: "updateproducts",
  DELETEPRODUCTIMAGES: "deleteproductimage",
  PRICEHISTORY: "getpricehistory",
  GETPRODUCTMEDIA: "getproductmedia",
  GETPRICECALCULATIONDATA: "getpricecalculationdata",
  UPDATEPRICECALCULATION: "updatepricecalculation",
  TOTALPRODUCT: "totalproduct",
  TOTALMONTHPRODUCT: "totalmonthproduct",
  GETWEBSETTING: "getwebsetting",
  UPDATEWEBSETTING: "updatewebsetting",
  USERINSERT: "insertuser",
  USERPHONEEXIST: "userphoneexist",
  USEREMAILEXIST: "useremailexist",
  GETUSER: "getuserdetails",
  UPADTEUSER: "updateuser",
  DELETEUSER: "deleteuser",
  UPDATEPRICE: "updateprice",
  GETPRODUCTHISTORY: "getproducthistory",
  GETUSERSOPTION: "getuser",
  GETALLSUGGESSION: "getallproductsuggession",
  INSERTMOSTSELLINGPRODUCT: "insertmostsellingproduct",
  GETMOSTSELLINGPRODUCT: "getmostsellingproduct",
  UPDATEMOSTSELLINGPRODUCT: "updatemostsellingproduct",
  DELETEMOSTSELLINGPRODUCT: "deletemostsellingproduct",
  GETSUGGESSIONFORMOSTSELLINGPRODUCT: "getmostsellingproductsuggession",
  IMPORTMOSTSELLINGPRODUCT: "importmostsellingproduct",
  // forgot password
  FORGOTPASSWORD: "sendmail",
  RESETPASSWORD: "resetpassword",

  // SELLER ACCOUNT NAME
  INSERTSELLERACCOUNTNAME: "insertselleraccountname",
  GETSELLERACCOUNTLIST: "getselleraccountlist",
  UPDATESELLERACCOUNTNAME: "updateselleraccountname",
  DELETESELLERACCOUTNAME: "deleteselleraccountname",

  // WAREHOUSEP RODUCTS
  INSERTWAREHOUSEPRODUCTS: "insertwarehouseproducts",
  IMPORTWAREHOUSEPRODUCTS: "importwarehouseproducts",
  GETWAREHOUSEPRODUCTSDATA: "getwarehouseproductsdata",
  UPDATEWAREHOUSEPRODUCTSDATA: "updatewarehouseproductsdata",
  GETSUGGESTIONDATA: "getsuggessiondata",
  DELETEWAREHOUSEPRODUCTSDATA: "deletewarehouseproduct",

  //OUT OF STOCK PRODUCT

  INSERTOUTOFSTOCKPRODUCT: "insertoutofstockproduct",
  GETOUTOFSTOCKPRODUCT: "getoutofstockproducts",
  UPDATEOUTOFSTOCKPRODUCT: "updateoutofstockproduct",
  DELETEOUTOFSTOCKPRODUCT: "deleteoutofstockproduct",
  IMPORTOUTOFSTOCKPRODUCT: "importoutofstockproduct",

  //TASK OF EMPLOYEE

  IMPORTTASK: "importtask",
  GETTASK: "gettask",
  UPDATETASK: "updatetask",
  DELETETASK: "deletetask",
  GETTASKSUGGESION: "suggestiontaskdata",

  GETBRANDLIST: "getbrandlist",

  //UPDATE PRODUCT PRICE

  IMPORTPRICEUPDATE: "importpriceupdate",
  GETPRICEUPDATE: "getpriceupdate",
  UPDATEPRICEUPDATE: "updatepriceupdate",
  UPDATEPRICEUPDATESTATUS: "updatepriceupdatestatus",
  GETBATCHIDSUGGESSION: "getbatchidsuggession",
  INSERTIMAGE: "insertimage",
  GETUPDATEPRICEMEDIA: "getupdatepricemedia",
  DELETEPRICEUPDATEIMAGE: "deletepriceupdateimage",
  DELETEPRICEUPDATE: "deletepriceupdate",
  GETUPDATEPRODUCTBRANDLIST: "getupdateproductbrandlist",
};
